<template>
  <div>
    <table>
      <thead class="evaluation-header-table">
        <tr>
          <td class="left-cell">Docente:</td>
          <td class="center-cell">
            {{
              getEvaluationProfessor.first_name ||
              getEvaluationProfessor.last_name
                ? getEvaluationProfessor.first_name +
                  " " +
                  getEvaluationProfessor.last_name
                : getEvaluationProfessor.email
            }}
            <template v-if="getEvaluationProfessor.email">
              <b-link
                :key="`icon-${getEvaluationProfessor.id}`"
                :href="`mailto:${getEvaluationProfessor.email}`"
                class="mr-2 noprint"
                ><b-icon icon="envelope" animation="pulse"></b-icon
              ></b-link>
            </template>
          </td>
          <td rowspan="7" style="width: 20%">
            <b-img v-bind:src="logo" :alt="school.name" width="100%"></b-img>
          </td>
        </tr>
        <tr>
          <td class="left-cell">
            {{ professors.length > 1 ? "Editores" : "Editor" }}:
          </td>
          <td class="center-cell">
            <template v-for="(professor, index) in professors">
              <span :key="professor.id" :title="professor.email" class="pr-2"
                >{{
                  professor.firts_name || professor.last_name
                    ? professor.first_name + " " + professor.last_name
                    : professor.email
                }} <span v-if="index < professors.length - 1"> <b>-</b> </span>
              </span>
            </template>
          </td>
        </tr>
        <tr>
          <td class="left-cell">Fecha de Creación:</td>
          <td class="center-cell">
            <div class="time-cell">
              <template>
                <div class="time-card">
                  {{ Evaluation.creation_date | FormatToDateTime }}
                  <!-- -
                  {{ Evaluation.end_date | FormatToDateTime }} -->
                </div>
              </template>
              <template v-if="Evaluation.finished">
                <div class="time-label start-time-label">Finalizado</div>
              </template>
              <template v-else-if="time_to_start">
                <div class="time-label start-time-label">
                  Tiempo para comenzar:
                </div>
                <div class="time-card">
                  {{ time_to_start | toTime }}
                </div>
              </template>
              <template v-else-if="time_to_end">
                <div class="time-label left-time-label">Tiempo restante:</div>
                <div class="time-card left-time-card">
                  {{ time_to_end | toTime }}
                </div>
              </template>
            </div>
          </td>
        </tr>
        <tr>
          <td class="left-cell px-1">Última Modificación:</td>
          <td class="center-cell">
            {{ Evaluation.update_date | FormatToDateTime }}
          </td>
        </tr>
        <tr>
          <td class="left-cell">Tipo:</td>
          <td class="center-cell">{{ evaluation_type }}</td>
        </tr>
        <!-- <tr>
          <td class="left-cell">Categoría:</td>
          <td class="center-cell">{{ evaluation_category }}</td>
        </tr> -->
        <slot></slot>
      </thead>
    </table>
    <b-tabs> </b-tabs>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "EvaluationHeader",
  props: {
    Evaluation: {
      type: Object,
      required: true,
    },
    evaluation_type: {
      type: String,
    },
    // evaluation_category: {
    //   type: String,
    // },
    time_to_start: {},
    time_to_end: {},
    is_running: {},
  },
  data() {
    return {
      // logo: this.$logo,
      // logo: require("@/assets/autonoma.png"),
    };
  },
  filters: {
    toTime(date) {
      if (!date) return "";
      return moment(date).utc().format("HH:mm:ss");
    },
  },
  computed: {
    ...mapGetters({
      studyUnits: names.STUDY_UNITS,
      matters: names.MATTERS,
      careers: names.CAREERS,
      campuses: names.CAMPUSES,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      egressProfiles: names.EGRESS_PROFILES,
      sections: names.SECTIONS,
      users: names.USERS,
      school: "getInstitution",
      images: names.IMAGES,
      user: "getUser",
    }),
    logo() {
      const image = this.images.find((x) => x.id == this.school.image);
      if (image) return image.image;
      return this.$logo ? this.$logo : "";
    },
    getEvaluationProfessor() {
      if (!this.Evaluation) return;
      let evaluation_professor = this.users.find(
        (x) => x.id == this.Evaluation.created_by
      );
      return evaluation_professor ? evaluation_professor : "";
    },
    getEvaluationSections() {
      return this.sections.find((x) => this.Evaluation.sections.includes(x.id));
    },
    studyUnit() {
      return this.studyUnits.find((x) => x.id == this.Evaluation.study_unit);
    },
    oldMatter() {
      if (!this.section) return null;
      return this.matters.find((x) => x.id == this.matter.matter);
    },
    matter() {
      if (!this.section) return null;
      return this.egress_profiles_matters.find(
        (x) => x.id == this.section.egress_profile_matter
      );
    },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) => x.id == this.egressProfile.career);
    },
    campus() {
      if (!this.section) return null;
      return this.campuses.find((x) => x.id == this.section.campus);
    },
    egressProfile() {
      if (!this.matter) return null;
      return this.egressProfiles.find(
        (x) => x.id == this.matter.egress_profile
      );
    },
    professors() {
      let list = [];
      // if (!this.section) return list;
      // this.section.professors.forEach((element) => {
      //   const professor = this.users.find((x) => x.id == element);
      //   if (professor) list.push(professor);
      // });
      if (!this.Evaluation) return list;
      this.users.forEach((user) => {
        const is_professor = this.Evaluation.editors.includes(user.id);
        if (is_professor) list.push(user);
      });
      return list;
    },
  },
  methods: {
    fetchMatter(matter_id) {
      if (this.matters.findIndex((x) => x.id == matter_id) == -1) {
        this.$store.dispatch(names.FETCH_MATTER, matter_id);
      }
    },
    fetchEgressProfile(egress_profile_id) {
      if (
        this.egressProfiles.findIndex((x) => x.id == egress_profile_id) == -1
      ) {
        this.$store
          .dispatch(names.FETCH_EGRESS_PROFILE, egress_profile_id)
          .then((response) => {
            this.fetchCareer(response.career);
            this.fetchCampus(response.campus);
          });
      } else {
        this.fetchCareer(this.egressProfile.career);
        this.fetchCampus(this.egressProfile.campus);
      }
    },
    fetchCareer(career_id) {
      if (this.careers.findIndex((x) => x.id == career_id) == -1) {
        this.$store.dispatch(names.FETCH_CAREER, career_id);
      }
    },
    fetchCampus(campus_id) {
      if (
        campus_id &&
        this.campuses.findIndex((x) => x.id == campus_id) == -1
      ) {
        this.$store.dispatch(names.FETCH_CAMPUS, campus_id);
      }
    },
    fetchSection() {
      if (this.Evaluation) {
        this.Evaluation.sections.forEach((section_id) => {
          if (this.user.sections_as_professor.find((x) => x == section_id)) {
            this.$store.dispatch(names.FETCH_SECTION, section_id);
          }
        });
      }
    },
  },
  watch: {
    Evaluation() {
      this.fetchSection();
    },
  },
  created() {
    this.fetchSection();
    if (this.school.image)
      this.$store.dispatch(names.FETCH_IMAGE, this.school.image);
    this.$store.dispatch(names.FETCH_USERS);
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border: 1px solid black;
  font-size: var(--secondary-font-size);
  font-weight: bold;
}
tr,
td {
  border: 1px solid black;
}
.left-cell {
  text-align: center;
  width: 15%;
}
.center-cell {
  text-align: left;
  width: 65%;
  padding-left: 1em;
  padding-right: 1em;
}
.time-label {
  width: fit-content;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  margin-right: 7px;
}
.left-time-label {
  background-color: rgb(235, 70, 70);
}
.start-time-label {
  background-color: green;
}
.time-card {
  width: fit-content;
  margin-right: 10px;
}
.time-cell {
  display: flex;
  flex-direction: row;
}
table >>> thead {
  border-radius: 4px !important;
  padding: 0.3em;
}
</style>